import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private translateService: TranslateService, private sessionService: SessionService) { }

  public changeLang(lang: string) {
    this.translateService.use(lang);
    this.sessionService.set('lang', lang);
  }

  public loadPreferences() {
    if (this.sessionService.get('lang')) {
      this.changeLang(this.sessionService.get('lang'));
    }
  }
}
