import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-feature',
  templateUrl: './intro-feature.component.html',
  styleUrls: ['./intro-feature.component.scss']
})
export class IntroFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
