import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailModel} from '../share/models/emailModel';
import '../../assets/js/smtp.js';

declare let Email: any;

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  showForm = false;
  clicked = false;
  emailForm: FormGroup;
  email: EmailModel = new EmailModel();

  constructor() { }

  ngOnInit() {
    this.emailForm = new FormGroup({
      lastname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      email: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      telephone: new FormControl('', [Validators.required, Validators.maxLength(100)])
    });
  }

  openForm() {
    this.showForm = true;
  }

  closeForm() {
    this.showForm = true;
  }

  public sendEmail = (formValue) => {
    // Get the snackbar span
    const successTxt = document.getElementById('successMsg');
    const failureTxt = document.getElementById('failureMsg');

    this.clicked = true;
    this.email = {
      lastname: formValue.lastname,
      email: formValue.email,
      telephone: formValue.telephone
    };
    Email.send({
      SecureToken: '2b129b36-cdc7-4cf4-85c8-2ec7eddbd807', // Port is 587
      To : 'contact@rsyssolution.com',
      From : 'admin@rsyssolution.com',
      Subject : 'Formulaire site web',
      // tslint:disable-next-line: max-line-length
      Body : `  <i>This is sent from rsyssolution.com form</i> <br/> <b>Name: </b>` + this.email.lastname
      + `</b><br/><b>Téléphone: </b>` + this.email.telephone + `</b> `
      + `</b><br/><b>Email: </b>` + this.email.email + `</b> `
    }).then( message => {
      this.clicked = false;
      if (message === 'OK') {
        this.emailForm.reset();
        // Add the "show" class to DIV
        successTxt.className = 'show';
        setTimeout(() => { successTxt.className = successTxt.className.replace('show', ''); }, 3000);
      } else {
        failureTxt.className = 'show';
        setTimeout(() => { failureTxt.className = failureTxt.className.replace('show', ''); }, 3000);
      }
      })
      .catch(e => {
        console.log(e);
      });
  }
}
