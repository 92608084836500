import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public  isShow;
  private topPosToStartShowing = 100;
  constructor() {
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  scrollToTop($element): void {
    const element = document.querySelector($element);
    if (element) { element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' }); }
  }
}
