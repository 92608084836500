import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SettingService} from '../share/services/setting/setting.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public currentLang: string ;
  constructor(private settingService: SettingService, private translateService: TranslateService) {
    this.currentLang = this.translateService.getDefaultLang();
  }

  ngOnInit() {
  }

  changeLang(lang: string) {
    this.settingService.changeLang(lang);
  }

}
