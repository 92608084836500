import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private cookieService: CookieService) { }

  public set(key: string, value: string): void {
    this.cookieService.set(key, value);
  }

  public get(key: string): string {
    return this.cookieService.get(key);
  }
}
