import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from './share/services/session/session.service';
import {SettingService} from './share/services/setting/setting.service';
import {NavigationService} from './share/services/navigation/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rsys-website';

  constructor(translate: TranslateService, sessionService: SessionService, settingService: SettingService, private navigationService: NavigationService) {
    translate.setDefaultLang('fr');
    settingService.loadPreferences();
  }
}
