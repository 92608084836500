import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FeatureComponent} from './feature/feature.component';
import {HomeComponent} from './home/home.component';
import {ContactFormComponent} from './contact-form/contact-form.component';
import { RsysMobileComponent } from './rsys-mobile/rsys-mobile.component';
import { FaqComponent } from './faq/faq.component';
import { BlogComponent } from './blog/blog.component';
import { HardwareComponent } from './hardware/hardware.component';
import { RsysClientComponent } from './rsys-client/rsys-client.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [

    // {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: '', component: HomeComponent},
    {path: 'home', component: HomeComponent},
    {path: 'contact', component: ContactFormComponent},
    {path: 'support', component: ContactFormComponent},
    {path: 'feature', component: FeatureComponent},
    {path: 'rsys-mobile', component: RsysMobileComponent},
    {path: 'rsys-client', component: RsysClientComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'about', component: AboutComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'hardware', component: HardwareComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
